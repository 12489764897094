import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';

export default function ReportList() {
    const { fetchReports, downloadReport } = useAuth();
    const [reports, setReports] = useState({});

    const downloadFile = report => {
        downloadReport(report).then(result => {
            if (result.type !== 'application/json') {
                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', report.url);
                document.body.appendChild(link);
                link.click();
            } else {
                const reader = new FileReader();
                reader.onload = function () {
                    const data = JSON.parse(result);
                    return data;
                };
                reader.readAsText(result);
            }
        });
    };

    return (
        <>
            <table>
                <thead>
                    <tr>My Reports</tr>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.length > 0 &&
                        reports.map(report => {
                            return (
                                <tr key={report.id}>
                                    <td>#{report.id}</td>
                                    <td>{report.name}</td>
                                    <td>
                                        <a href="javscript:;" onClick={() => downloadFile(report)}>
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </>
    );
}
